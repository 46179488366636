.priority_1 {
  background-color: rgb(255, 91, 91) !important;
  color: black;
}

.priority_2 {
  background-color: rgb(255, 255, 127) !important;
  color: black;
}

.priority_3 {
  background-color: rgb(61, 137, 61) !important;
  color: black;
}

.priority_4 {
  background-color: rgb(86, 86, 255) !important;
  color: black;
}

.acknowledged {
  background-color: lightgreen;
  color: black;
}

.pending {
  background-color: lightcoral;
  color: black;
}

.warning {
  background-color: var(--theme-palette-warning);
  color: black;
}

.snoozed {
  background-color: lightblue;
  color: black;
}

.success {
  background-color: green;
  color: black;
}

.error {
  background-color: var(--theme-palette-error);
  color: black;
}

.icon {
  font-size: 25;
}

.iconVariant {
  opacity: 0.9;
  margin-right: 10;
}

.message {
  display: flex;
  align-items: center;
}
