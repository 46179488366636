.themeTestLocal {
  background-color: var(--theme-palette-local-main);
}

.themeTestStaging {
  background-color: var(--theme-palette-staging-main);
}

.themeTestProduction {
  background-color: var(--theme-palette-production-main);
}

.button {
  white-space: nowrap;
  min-width: 291px;
}

.buttonDiv {
  text-align: center;
}

.buttonDivLeft {
  text-align: right;
  margin-right: 25px;
}

.buttonDivMiddleLeft {
  text-align: right;
}

.buttonDivMiddle {
  text-align: center;
  margin: auto;
}

.buttonDivRight {
  text-align: left;
  margin-left: 25px;
}

.buttonDivMiddleRight {
  text-align: left;
}
