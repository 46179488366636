div.searchPaper {
  align-items: center;
  display: flex;
  margin-right: var(--theme-spacing);
  padding: 2px 4px;
  width: 300px;
}

div.input {
  margin-left: var(--theme-spacing);
  flex: 1;
}

button.iconButton {
  padding: 10px;
}
