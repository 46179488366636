/* ICON BUTTON HOVER EFFECT */
.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
}

.iconWrapperDark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
}

.iconWrapper:hover .iconHover {
  transform: scale(1.08) !important;
  box-shadow: 0px 0px 5px 0.2px #b2ebf2 !important;
}

.iconWrapperDark:hover .iconHover {
  transform: scale(1.08) !important;
  box-shadow: 0px 0px 5px 0.2px #0f154b !important;
}

.iconHover {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 5px;
  color: white;
}

/* HIDDEN SCROLLBAR */
.scrollbarHidden::-webkit-scrollbar {
  display: none;
}

.scrollbarHidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

/* BLUE STYLED SCROLLBAR */
.blueStyledScrollbar::-webkit-scrollbar {
  width: 9px;
}

.blueStyledScrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #3d585b;
  border-left: 2px solid #3d585b;
  border-right: 2px solid #3d585b;
}

.blueStyledScrollbar::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
  border-left: 2px solid #3d585b;
}

/* CASEOPP BUTTONs */
.caseOppButton {
  background-color: #0f154b;
  color: white !important;
  font-size: 13px;
  padding: 10px;
  line-height: 1.2;
  width: 100%;
}

.caseOppButton:hover {
  background-color: #8ecccc !important;
  color: #0f154b !important;
}

.caseOppButton:disabled {
  background-color: #afafaf !important;
  color: #222222bc !important;
}

.inverseCaseOppButton {
  background-color: #8ecccc !important;
  color: #0f154b !important;
  font-size: 13px;
  padding: 10px;
  line-height: 1.2;
  width: 100%;
}
.inverseCaseOppButtonWithIcon {
  background-color: #8ecccc !important;
  color: #0f154b !important;
  font-size: 13px;
  padding: 5px;
  line-height: 1.2;
  width: 100%;
}

.inverseCaseOppButton:hover {
  background-color: #0f154b !important;
  color: white !important;
}

.inverseAlertCaseOppButton {
  background-color: rgb(237, 108, 2) !important;
  color: #0f154b !important;
  font-size: 13px;
  padding: 10px;
  line-height: 1.2;
  width: 100%;
}

.inverseAlertCaseOppButton:hover {
  background-color: #8ecccc !important;
  color: #0f154b !important;
}
