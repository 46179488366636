.menuButton {
  margin-left: 5px;
  margin-right: 10px;
}

.hide {
  display: none;
}

.toolbar {
  margin-right: 16px;
}
